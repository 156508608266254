/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// libraries
import React, {
    useState, useRef, useEffect, useReducer,
} from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import loadable from '@loadable/component';
import {
    object, string, func, bool, shape,
} from 'prop-types';
import { UIDReset, UIDConsumer } from 'react-uid';
import { withStyles } from '@material-ui/core/styles';
import { Query } from '@apollo/client/react/components';
import { bindActionCreators } from 'redux';
import mbpLogger from 'mbp-logger';
import { setCountry } from '../../../../state/ducks/App/App-Actions';
import { getCountryCodeByCategoryPath } from '../../../../state/ducks/Checkout/helpers/countryCode';
import { getFeatureFlags } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';

// mobile header ui components

// helpers, redux, gql
import { GRAPHQL_ENV } from '../../../gql';
import findHeader from '../../../gql/queries/findHeader';
import determineLocale from '../../../helpers/contentstack/determineLocale';

import { getCheckoutFeatureFlag } from '../../../../state/ducks/Checkout/ducks/App/App-Selectors';
import { getActiveABTest } from '../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';
import { getCurrentPageView } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Selectors';
import { getSiteWideBannerData } from './helper';
import useIsKeeper from '../../../helpers/hooks/useIsKeeper';

// mobile header ui components
const MobileHeaderBlock = loadable(() => import(/* webpackChunkName: "MobileHeaderBlock" */ './MobileHeaderBlock'));
const CheckoutHeaderMobile = loadable(() => import(/* webpackChunkName: "CheckoutHeaderMobile" */ './CheckoutHeaderFood/CheckoutHeaderMobile'));
const CheckoutBanner = loadable(() => import(/* webpackChunkName: "CheckoutBanner" */ '../../../pages/Checkout/containers/CheckoutFood/components/Partials/CheckoutBanner/CheckoutBanner'));
const SearchBlock = loadable(() => import(/* webpackChunkName: "SearchBlock" */ './SearchBlock'));
const HeaderBannersList = loadable(() => import(/* webpackChunkName: "SiteWideBanner" */ './SiteWideBanner/HeaderBannersList'));
const OfferBanner = loadable(() => import(/* webpackChunkName: "OfferBanner" */ '../OfferBanner/OfferBanner'));
const AboveBannerEyebrow = loadable(() => import(/* webpackChunkName: "OfferBanner" */ '../AboveBannerEyebrow/AboveBannerEyebrow'));

const TARGET_MOBILE = 'Mobile';

const styles = (theme) => ({
    appHeader: {
        color: theme.palette.grey?.['50'] || '#aeaeae',
        display: 'grid',
        gridTemplateColumns: '48px minmax(0, 60px) 1fr 60px 48px',
        gridTemplateRows: '54px',
        gridColumnGap: '0px',
        gridRowGap: '0px',
        width: '100%',
        boxSizing: 'border-box',
    },
    headerWrapper: {
        boxShadow: '0 3px 6px rgba(48,69,98,.16), 0 3px 6px rgba(48,69,98,.23)',
        // marginBottom: '10px', removed for now unless theres a reason
        backgroundColor: theme.palette.cms?.mobileBgHeader || theme.palette.bgHeader,
        width: '100%',
        zIndex: 1,
        // height: '54px',
    },
    shadowHide: {
        boxShadow: 'none',
    },
    clsNavHeight: {
        height: '54px',
    },
    clsFoodNavHeight: {
        height: '103px',
    },
    stickyHeader: {
        boxShadow: '0 3px 6px rgba(48,69,98,.16), 0 3px 6px rgba(48,69,98,.23)',
        position: 'fixed',
        top: 0,
        zIndex: 999,
    },
    gatheringsAppHeader: {
        [theme.breakpoints.down(1025)]: {
            gridTemplateColumns: '48px minmax(0, 60px) 3fr 1fr 60px 48px',
        },
        [theme.breakpoints.down(600)]: {
            gridTemplateColumns: '48px minmax(0, 60px) 1fr 1fr 60px 48px',
        },
        [theme.breakpoints.down(480)]: {
            gridTemplateColumns: '14% minmax(0, 60px) 28% 30% 6% 0%',
        },
        [theme.breakpoints.down(340)]: {
            gridTemplateColumns: '14% minmax(0, 60px) 25% 30% 6% 0%',
        },
    },
});

const MobileGraphqlHeader = (props) => {
    const {
        brand, classes, history, presentationFamily, layout, isSearchUiInMobileMenuEnabled,
        featureFlags, actionSetCountry, isEyeBrowShow, pageView, ffWhichCheckoutVariation,
        abTestCheckoutUniversal, deviceType, ffIsHideMobileHeaderOnScrollEnabled,
    } = props;
    const isKeeper = useIsKeeper();
    const [isCheckoutLocation, setIsCheckoutLocation] = useState(history?.location?.pathname.indexOf('/checkout/') > -1);

    // This hook handles the sticky header state
    const [isStickyHeader, setStickyHeader] = useReducer((_, action) => {
        const { height, sticky } = action;
        if (!isCheckoutLocation && sticky) {
            const rootElem = document.querySelector('#appshell-container');
            rootElem.setAttribute('style', `padding-top: ${height}px; position: static`);
            return true;
        }
        const rootElem = document.querySelector('#appshell-container');
        rootElem.setAttribute('style', 'padding-top: 0px; position: static');
        return false;
    }, false);
    const stickyHeaderRef = useRef(null);

    const lastScrollY = useRef(0);
    const handleScroll = () => {
        if (typeof window !== 'undefined' && !lastScrollY?.current) {
            if (stickyHeaderRef && stickyHeaderRef.current) {
                const { height } = stickyHeaderRef.current.getBoundingClientRect();
                setStickyHeader({ height, sticky: window.pageYOffset >= height });
            }
        }
    };

    const hideHeaderOnScroll = (position) => {
        if (stickyHeaderRef && stickyHeaderRef.current) {
            const { height } = stickyHeaderRef.current.getBoundingClientRect();
            stickyHeaderRef.current.style.position = 'fixed';
            stickyHeaderRef.current.style.transition = 'top 0.4s ease-out';
            if (position > lastScrollY.current && position >= height) {
                stickyHeaderRef.current.style.top = `-${height}px`;
                stickyHeaderRef.current.style.transform = `translateY(-${height}px)`;
            } else {
                stickyHeaderRef.current.style.top = '0px';
                stickyHeaderRef.current.style.transform = 'translateY(0)';
            }
            lastScrollY.current = position;
        }
    };

    useEffect(() => {
        const inCheckout = (
            (
                history?.location?.pathname?.indexOf('/checkout/') >= 0
                && ['flower', 'food'].includes(ffWhichCheckoutVariation)
                && abTestCheckoutUniversal !== 'universal'
            ) || (
                (['enterprise', 'enterprise2'].includes(ffWhichCheckoutVariation) || abTestCheckoutUniversal === 'universal')
                && history?.location?.pathname?.indexOf('/checkout/') >= 0
                && history?.location?.pathname?.indexOf('/checkout/cart') < 0
                && deviceType === 'desktop'
            )
        );

        setIsCheckoutLocation(inCheckout);
        if (isCheckoutLocation) {
            if (typeof document !== 'undefined' && document?.querySelector?.('#appshell-container')) {
                const rootElem = document.querySelector('#appshell-container');
                // setting header padding 0 as checkout isn't using sticky header
                rootElem.setAttribute('style', 'padding-top: 0px');
            }
        }
    }, [history]);

    const countryDetails = getCountryCodeByCategoryPath(history?.location?.pathname);
    const isCanadaContry = countryDetails?.countryName?.toLowerCase() === 'canada';
    useEffect(() => {
        if (countryDetails?.countryName) {
            actionSetCountry(countryDetails?.countryName);
        }

        // Add scroll event when the component is loaded
        // only run if auto-hide flag is disabled to handle default behavior and run only hideHeaderOnScroll method
        if (typeof document !== 'undefined' && (!ffIsHideMobileHeaderOnScrollEnabled)) {
            document.addEventListener('scroll', handleScroll);
        }
        // to handle auto-hide header if search bar present in left menu
        if (typeof document !== 'undefined' && (ffIsHideMobileHeaderOnScrollEnabled && isSearchUiInMobileMenuEnabled)) {
            document.addEventListener('scroll', () => hideHeaderOnScroll(window.scrollY));
        }
        return () => {
            if (typeof document !== 'undefined') {
                document.removeEventListener('scroll', handleScroll);
                const rootElem = document.querySelector('#appshell-container');
                // removing header style as moving out of mobile view
                if (rootElem) {
                    rootElem.removeAttribute('style');
                }
            }
        };
    }, [ffIsHideMobileHeaderOnScrollEnabled, isSearchUiInMobileMenuEnabled]);

    const HEADER_QUERY = findHeader(brand, determineLocale(history, isCanadaContry), TARGET_MOBILE, layout);

    const inCheckout = () => (
        (
            history?.location?.pathname?.indexOf('/checkout/') >= 0
            && ['flower', 'food'].includes(ffWhichCheckoutVariation)
            && abTestCheckoutUniversal !== 'universal'
        ) || (
            (['enterprise', 'enterprise2'].includes(ffWhichCheckoutVariation) || abTestCheckoutUniversal === 'universal')
            && history?.location?.pathname?.indexOf('/checkout/') >= 0
            && history?.location?.pathname?.indexOf('/checkout/cart') < 0
            && deviceType === 'desktop'
        )
    );

    const isGatheringsPage =  history?.location?.pathname === '/gatheringus' && presentationFamily === 'flower';

    return (
        <Query query={HEADER_QUERY}>
            {({ loading, error, data }) => {
                if (loading) {
                    return <div className={`${classes.headerWrapper} ${isSearchUiInMobileMenuEnabled ? classes.clsNavHeight : classes.clsFoodNavHeight}`} />; // skeleton component
                }

                if (error) {
                    mbpLogger.logError({
                        appName: process.env.npm_package_name,
                        query: HEADER_QUERY,
                        component: 'MobileGraphqlHeader.js',
                        message: 'Error loading data from Graphql',
                        env: GRAPHQL_ENV,
                        error,
                    });
                    return null;
                }

                if (!data) {
                    mbpLogger.logWarning({
                        appName: process.env.npm_package_name,
                        query: HEADER_QUERY,
                        component: 'MobileGraphqlHeader.js',
                        message: 'No data returned for query',
                        env: GRAPHQL_ENV,
                    });
                    return null;
                }

                // No Entries
                if (
                    !data
                    || !data.findHeader
                    || !data.findHeader.content
                    || !data.findHeader.content.entries.length
                ) {
                    mbpLogger.logError({
                        appName: process.env.npm_package_name,
                        message: 'No header blocks returned from API in MobileGraphqlHeader',
                        brand,
                    });
                    return null;
                }
                // below if temporary due to apollo upgrade -> immuatable objects break current implementation of assigning
                // variables and properties to object returned from apollo
                if (!data) {
                    return <div className={`${classes.headerWrapper} ${presentationFamily === 'flower' && classes.clsNavHeight}`} />;
                }

                // data
                if (data?.findHeader?.content?.entries?.[0]?.header_blocks && data.findHeader.content.entries[0].header_blocks.length) {
                    const entries = data.findHeader.content.entries[0];
                    const headerBlocks = entries.header_blocks;
                    const eyeBrow = entries.eyebrow;
                    const hasCountdownBanner = eyeBrow?.countdown_banner?.[0];
                    const siteWideBannerContentZones = entries?.site_wide_banner_content_zones || [];
                    const siteWideBannerPZCampaignId = entries?.site_wide_banner_pz_campaign_id || null;
                    const siteWideBannerFallbackUID = entries?.site_wide_banner_fallback_uid || null;
                    const siteWideBannerSkeletonHeight = entries?.site_wide_banner_skeleton_height || null;
                    const siteWideBannerSkeletonWidth = entries?.site_wide_banner_skeleton_width || null;

                    const  { bannerDataBottom, bannerDataTop } =  getSiteWideBannerData(entries.site_wide_banner || []);

                    return (
                        <>
                            {hasCountdownBanner && <OfferBanner menu={eyeBrow.countdown_banner[0]} />}
                            {bannerDataTop?.length > 0 && <HeaderBannersList banners={bannerDataTop} isEyeBrowShow />}
                            {
                                isEyeBrowShow
                                && eyeBrow
                                && featureFlags['is-covid-eyebrow-enabled']
                                && !history.location.pathname.includes('checkout')
                                && !history.location.search.includes('utm_medium=bgs')
                                && pageView.pageType === 'home'
                                &&  (
                                    <AboveBannerEyebrow
                                        location={history.location}
                                        data={eyeBrow}
                                    />
                                )
                            }
                            <div className={`${classes.headerWrapper} ${isSearchUiInMobileMenuEnabled ? classes.clsNavHeight : classes.clsFoodNavHeight} ${isStickyHeader ? classes.stickyHeader : classes.notStickyHeader}`} ref={stickyHeaderRef} data-testid="is-sticky-header">
                                <div className={ffWhichCheckoutVariation !== 'food' && isCheckoutLocation ? classes.appHeaderNone : `${classes.appHeader} ${(isGatheringsPage || isKeeper) ? classes.gatheringsAppHeader : ''}`} id="headerContent">
                                    <UIDReset>
                                        <UIDConsumer>
                                            {(id, uid) => headerBlocks.map(
                                                (block) => {
                                                    const blockData = block;

                                                    if (inCheckout()) {
                                                        return ffWhichCheckoutVariation === 'food'
                                                            ? (
                                                                <>
                                                                    <CheckoutHeaderMobile key={uid(blockData)} brand={brand} block={blockData} presentationFamily={presentationFamily} />
                                                                </>
                                                            )
                                                            : null;
                                                    }

                                                    return (
                                                        <MobileHeaderBlock
                                                            keeperData={entries?.keeper || {}}
                                                            key={uid(blockData)}
                                                            block={blockData}
                                                            brand={brand}
                                                            isGatheringsPage={isGatheringsPage}
                                                            presentationFamily={presentationFamily}
                                                            logo={(headerBlocks || []).find?.((item) => Object.keys(item || {})?.includes?.('logos')) || {}}
                                                            isSearchUiInMobileMenuEnabled={isSearchUiInMobileMenuEnabled}
                                                        />
                                                    );
                                                },
                                            )}
                                        </UIDConsumer>
                                    </UIDReset>
                                </div>
                                {!isSearchUiInMobileMenuEnabled && (
                                    <SearchBlock
                                        brand={brand}
                                        typeSearchBox="header"
                                        hideHeaderOnScroll={hideHeaderOnScroll}
                                        isSearchUiInMobileMenuEnabled={isSearchUiInMobileMenuEnabled}
                                    />
                                )}
                            </div>

                            {bannerDataBottom?.length > 0 && <HeaderBannersList banners={bannerDataBottom} siteWideBannerPZCampaignId={siteWideBannerPZCampaignId} siteWideBannerContentZones={siteWideBannerContentZones} siteWideBannerFallbackUID={siteWideBannerFallbackUID} siteWideBannerSkeletonHeight={siteWideBannerSkeletonHeight} siteWideBannerSkeletonWidth={siteWideBannerSkeletonWidth} isMobile />}
                            {inCheckout() && <CheckoutBanner />}
                        </>
                    );
                }
                return <>No Header</>;
            }}
        </Query>
    );
};

const mapStateToProps = (state) => ({
    featureFlags: getFeatureFlags(state),
    ffWhichCheckoutVariation: getCheckoutFeatureFlag('which-checkout-variation')(state),
    abTestCheckoutUniversal: getActiveABTest('checkout')(state),
    pageView: getCurrentPageView(state),
});
const mapDispatchToProps = (dispatch) => ({
    actionSetCountry: bindActionCreators(setCountry, dispatch),
});

MobileGraphqlHeader.propTypes = {
    brand: object.isRequired,
    classes: object.isRequired,  // added by withStyles HOC
    history: object.isRequired,
    presentationFamily: string.isRequired,
    layout: string,
    featureFlags: object.isRequired,
    actionSetCountry: func.isRequired,
    isEyeBrowShow: bool.isRequired,
    pageView: shape({
        pageName: string,
        pageType: string,
    }).isRequired,
    ffWhichCheckoutVariation: string,
    ffIsHideMobileHeaderOnScrollEnabled: bool,
    isSearchUiInMobileMenuEnabled: bool,
    abTestCheckoutUniversal: string,
    deviceType: string.isRequired,
};
MobileGraphqlHeader.defaultProps = {
    ffWhichCheckoutVariation: '',
    ffIsHideMobileHeaderOnScrollEnabled: false,
    isSearchUiInMobileMenuEnabled: false,
    abTestCheckoutUniversal: '',
    layout: '',
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MobileGraphqlHeader)));
